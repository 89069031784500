@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');

body {
  background-color: #123231;
  font-family: 'Press Start 2P', cursive;
  margin: 0;
  padding: 0;
}

.app-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 20px;
  box-sizing: border-box;
}

.game-box {
  background-color: #123231;
  border: 4px solid #FC731B;
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  position: relative;
  width: 100%;
  max-width: 600px;
  min-height: 400px;
  display: flex;
  flex-direction: column;
}

.pixel-text {
  color: #ecf0f1;
  text-shadow: 2px 2px #2c3e50;
}

.input-form {
  margin: 20px 0;
}

.pixel-input {
  font-family: 'Press Start 2P', cursive;
  font-size: 14px;
  padding: 10px;
  margin-bottom: 10px;
  border: none;
  background-color: #ecf0f1;
  color: #2c3e50;
  width: 100%;
  max-width: 300px;
}

.pixel-button {
  font-family: 'Press Start 2P', cursive;
  font-size: 14px;
  padding: 10px 20px;
  background-color: #e74c3c;
  color: #ecf0f1;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
}

.pixel-button:hover {
  background-color: #c0392b;
}

.countdown {
  font-size: 48px;
  color: #e74c3c;
}

.status {
  margin-top: 20px;
  color: #3498db;
}

canvas {
  border: 4px solid #e74c3c !important;
  border-radius: 10px;
  max-width: 100%;
  height: auto;
}

.instruction {
  font-size: 12px;
  color: #ecf0f1;
  text-shadow: 2px 2px #2c3e50;
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
  margin: 0 auto;
}

@media (max-width: 768px) {
  .game-box {
    padding: 15px;
  }

  h1 {
    font-size: 18px;
  }

  .pixel-input, .pixel-button {
    font-size: 12px;
  }

  .countdown {
    font-size: 36px;
  }

  .instruction {
    font-size: 10px;
  }
}

@media (max-width: 480px) {
  .game-box {
    padding: 10px;
  }

  h1 {
    font-size: 16px;
  }

  .pixel-input, .pixel-button {
    font-size: 10px;
  }

  .countdown {
    font-size: 24px;
  }

  .instruction {
    font-size: 8px;
  }
}
