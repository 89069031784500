.game-container {
  position: relative;
  width: 400px;
  height: 400px;
  margin: 0 auto; /* Center the game container */
  display: flex;
  justify-content: center;
  align-items: center;
}

.game-canvas {
  border: 1px solid black;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.play-button {
  background-color: #4CAF50;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.pixelated {
  font-family: 'Press Start 2P', cursive; /* Use a pixelated font */
  image-rendering: pixelated;
  image-rendering: crisp-edges;
}

.play-button:hover {
  background-color: #45a049;
  transform: scale(1.1);
}

.score {
  position: absolute;
  top: 10px;
  left: 10px;
  font-size: 18px;
  color: white;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.5);
}
